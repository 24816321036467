<template>
  <div v-if="$apollo.loading">Loading...</div>
  <div v-else>
    <div class="wrapper">
      <v-form class="form" @submit.stop.prevent="submit">
        <div class="main" style="background:#f1f1f1;">
          <div class="section">
            <div class="container">
              <h3 class="card-title">Product Builder</h3>

              <div class="row">
                <div class="col-lg-12">
                  <div v-if="currentUser.expert"></div>

                  <div v-else>
                    <div class="container">
                      <div class="row">
                        <div class="col-12">
                          <p>
                            This is where you enter in the product info to
                            create your products. Once you have the product
                            created you can create variants.

                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label class="labels" for="#firstName">
                    <strong> Product Name </strong></label
                  >
                  <v-text-field
                    id="name"
                    v-model="product.name"
                    placeholder="Product Name"
                    background-color="white"
                    required
                    filled
                  ></v-text-field>

                  <label class="labels" for="#firstName"
                    ><strong> Description </strong>
                  </label>
                  <ckeditor
                    v-if="long_description != null"
                    v-model="product.long_description"
                    :editor="editor"
                    :config="editorConfig"
                  ></ckeditor>

                  <div class="row mt-4">
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#email"
                        >Use Tags to sort and organize your Products</label
                      >
                    </div>
                    <div class="col-md-9 align-self-center">
                      <v-combobox
                        v-model="chips"
                        :items="tagNames"
                        chips
                        clearable
                        :search-input.sync="chipsearch"
                        label="Enter Tags followed by Enter key. (TAGS are a OR Logic)"
                        multiple
                        hide-selected
                        solo
                        @change="chipsearch = ''"
                      >
                        <template
                          #selection="{
                            attrs,
                            item,
                            select,
                            parent,
                          }"
                        >
                          <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            @click="select"
                            @click:close="parent.selectItem(item)"
                          >
                            <strong>{{ item }}</strong
                            >&nbsp;
                          </v-chip>
                        </template>
                        <template #no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                No results matching "<strong>{{
                                  chipsearch
                                }}</strong
                                >". Press <kbd>enter</kbd> to create a new one
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </div>
                  </div>

                  <label class="labels mt-6" for="#firstName"
                    ><strong>Short Description </strong>
                  </label>
                  <div class="pb-4">
                    <ckeditor
                      v-if="short_description != null"
                      v-model="product.short_description"
                      :editor="editor"
                      :config="editorConfig"
                    ></ckeditor>
                  </div>

                  <v-expansion-panels v-model="panel2" multiple>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <v-toolbar-title> Variants / Sku's</v-toolbar-title>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-toolbar flat>
                          <v-spacer></v-spacer> <v-spacer></v-spacer>

                          <v-btn
                            small
                            color="teal"
                            class="mr-3"
                            @click="addDialog = true"
                          >
                            New Item
                          </v-btn>
                        </v-toolbar>

                        <b-table striped hover :items="items" :fields="fields">
                          <template #cell(name)="data">
                            {{ data.item.Name }}
                          </template>
                          <template #cell(sku)="data">
                            {{ data.item.sku_id }}
                          </template>
                          <template #cell(qty)="data">
                            <v-text-field
                              dense
                              class=""
                              style="width:75px;"
                              readonly
                              :placeholder="`${data.item.qty}`"
                              filled
                            ></v-text-field>
                          </template>
                          <template #cell(actions)="data">
                            <v-btn
                              icon
                              class=""
                              dark
                              color="success"
                              @click="
                                $router.push('/pim/item-edit/' + data.item.id)
                              "
                            >
                              <v-icon small>mdi-pencil</v-icon>
                            </v-btn>

                            <v-btn
                              icon
                              dark
                              class=""
                              color="success"
                              @click="removeItemBtn(data.item.id)"
                            >
                              <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                            <v-tooltip bottom>
                              <template #activator="{ on, attrs }">
                                <div
                                  v-if="data.item.pricelist_items.length > 0"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-btn icon dark class="" color="success">
                                    <v-icon small
                                      >mdi-information-outline</v-icon
                                    >
                                  </v-btn>
                                </div>
                              </template>
                              <v-card outlined shaped tile>
                                <v-list-item
                                  v-for="price in data.item.pricelist_items"
                                  :key="price.id"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Quantity: {{ price.qty }} Price:
                                      {{
                                        price.price | dollar_format
                                      }}</v-list-item-title
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                              </v-card>
                            </v-tooltip>
                          </template>
                        </b-table>
                        <div class="row mt-8">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="itemsCount"
                            :per-page="limit"
                            style="margin: 0 auto;"
                          ></b-pagination>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <v-toolbar-title> Attributes</v-toolbar-title>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-card-text>
                          <div class="row">
                            <div class="col-lg-6">
                              <!-- <div class="form-group row">
                                <label class="col-5 col-form-label"
                                  >Product Type</label
                                >
                                <div class="col-7">
                                  <b-form-select
                                    id="input-horizontal"
                                    v-model="type"
                                    :options="type_options"
                                  ></b-form-select>
                                </div>
                              </div> -->

                              <div class="form-group row">
                                <label class="col-5 col-form-label"
                                  >Secondary Name</label
                                >
                                <div class="col-7">
                                  <input
                                    v-model="product.second_name"
                                    class="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-5 col-form-label"
                                  >Case Pack</label
                                >
                                <div class="col-7">
                                  <input
                                    v-model="product.case_pack"
                                    class="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-5 col-form-label"
                                  >Brand</label
                                >
                                <div class="col-7">
                                  <input
                                    id="brand"
                                    v-model="product.brand"
                                    class="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-group row">
                                <label class="col-5 col-form-label"
                                  >Weight LBS</label
                                >
                                <div class="col-7">
                                  <input
                                    id="weight"
                                    v-model="product.weight"
                                    class="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-5 col-form-label"
                                  >Length</label
                                >
                                <div class="col-7">
                                  <input
                                    id="length"
                                    v-model="product.length_in"
                                    class="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-5 col-form-label"
                                  >Width</label
                                >
                                <div class="col-7">
                                  <input
                                    id="width"
                                    v-model="product.width_in"
                                    class="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-5 col-form-label"
                                  >Height</label
                                >
                                <div class="col-7">
                                  <input
                                    id="height"
                                    v-model="product.height_in"
                                    class="form-control"
                                    type="number"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group row">
                                <label class="col-12 col-form-label">
                                  Shipping Info</label
                                >

                                <div class="col-12">
                                  <ckeditor
                                    v-model="product.shipping_info"
                                    :editor="editor"
                                    :config="editorConfig"
                                  ></ckeditor>

                                  <pre class="mt-3 mb-0"></pre>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-12">
                              <div class="form-group row">
                                <label class="col-12 col-form-label">
                                  Marketing text</label
                                >

                                <div class="col-12">
                                  <ckeditor
                                    v-model="product.marketing_text"
                                    :editor="editor"
                                    :config="editorConfig"
                                  ></ckeditor>

                                  <pre class="mt-3 mb-0"></pre>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-12">
                              <div class="form-group row">
                                <label class="col-12 col-form-label">
                                  Ingredients text</label
                                >

                                <div class="col-12">
                                  <ckeditor
                                    v-model="product.ingredients"
                                    :editor="editor"
                                    :config="editorConfig"
                                  ></ckeditor>

                                  <pre class="mt-3 mb-0"></pre>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-card-text>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="justify-md-right"></div>
                      <div class="mb-2" style="float:right;">
                        <v-btn
                          elevation="9"
                          rounded
                          small
                          color="orange  darken-2"
                          @click="$router.go(-1)"
                        >
                          <v-icon left>
                            mdi-arrow-left
                          </v-icon>
                          Back
                        </v-btn>

                        <v-btn
                          elevation="9"
                          rounded
                          medium
                          color="success"
                          type="submit"
                          class="mr-2 align-items-right"
                        >
                          <v-icon left>
                            mdi-content-save
                          </v-icon>
                          Save
                        </v-btn>
                      </div>
                    </div>

                    <div class="col-md-6"></div>

                    <div class="col-md-6">
                      <v-checkbox v-model="featured" label="Feature on Profile">
                      </v-checkbox>
                    </div>

                    <div class="col-md-12">
                      Preview product once saved.
                      <v-btn
                        small
                        :href="
                          'https://www.goosedirect.com/product/' + $route.params.id
                        "
                        target="_blank"
                      >
                        Customer View
                      </v-btn>
                    </div>
                    <div class="col-md-12">
                      <strong> Status </strong>
                      <b-form-select
                        id="input-horizontal"
                        v-model="status"
                        :options="product_options"
                      ></b-form-select>
                    </div>

                    <div class="col-md-12">
                      <label class="labels" for="#productid"
                        ><strong> Product ID </strong></label
                      >

                      <v-text-field
                        id="part_id"
                        v-model="product.part_id"
                        filled
                        background-color="white"
                        placeholder="Product ID"
                        required
                      ></v-text-field>
                    </div>

                    <v-expansion-panels v-model="panel" multiple>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          Product Image
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <section class="mt-2  mb-6">
                            <v-img
                              class="mb-8"
                              :src="
                                product.main_image
                                  ? product.main_image.url
                                  : '/media/product-placeholder.jpg'
                              "
                            >
                              <v-btn
                                icon
                                class="float-right"
                                style="background-color: transparent;"
                                @click="removeImg(product.main_image.id)"
                              >
                                <v-icon style="color: black;">mdi-close</v-icon>
                              </v-btn>
                            </v-img>

                            <v-spacer class="mb-4"> </v-spacer>

                            <b-form-file
                              v-model="main_image"
                              :state="Boolean(main_image)"
                              :placeholder="
                                product.main_image
                                  ? product.main_image.name
                                  : 'Upload Image'
                              "
                              drop-placeholder="Drop file here..."
                              accept="image/*"
                            ></b-form-file>
                          </section>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          Product Gallery
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row class="mr-1">
                            <v-col
                              v-for="(img, index) in additional_images"
                              :key="index"
                              cols="6"
                              sm="6"
                            >
                              <v-img
                                :src="
                                  img.url
                                    ? img.url
                                    : 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg'
                                "
                              >
                                <div class=" repeating-gradient">
                                  <v-btn
                                    icon
                                    small
                                    class="float-right"
                                    style="background-color: #fff .5;"
                                    @click="removeImg(img.id)"
                                  >
                                    <v-icon style="color: black;"
                                      >mdi-close</v-icon
                                    >
                                  </v-btn>
                                </div>
                              </v-img>
                            </v-col>

                            <!-- <v-col
                              v-for="(img, index) in additional_images"
                              :key="index"
                              cols="6"
                              sm="6"
                            >
                              <v-img
                                :src="
                                  img.url != ''
                                    ? baseurl + img.url
                                    : 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg'
                                "
                              >
                                <div class=" repeating-gradient">
                                  <v-btn
                                    icon
                                    small
                                    class="float-right"
                                    style="background-color: #fff .5;"
                                    @click="removeImg(img.id)"
                                  >
                                    <v-icon style="color: black;"
                                      >mdi-close</v-icon
                                    >
                                  </v-btn>
                                </div>
                              </v-img>
                            </v-col>
                            <v-col cols="6" sm="4">
                              <image-input
                                v-model="additional_image"
                                :state="Boolean(additional_image)"
                              >
                                <div slot="activator">
                                  <v-img
                                    v-if="!additional_image"
                                    v-ripple
                                    src="/media/upload.png"
                                    class="grey lighten-3 mb-3"
                                  >
                                  </v-img>
                                  <v-img
                                    v-else
                                    v-ripple
                                    size="150px"
                                    class="mb-3"
                                  >
                                    <img
                                      :src="additional_image.imageURL"
                                      alt="avatar"
                                    />
                                  </v-img>
                                </div>
                              </image-input>
                              <v-slide-x-transition>
                                <div v-if="additional_image && saved == false">
                                  <v-btn class="primary" @click="addimageUpload"
                                    >Save image</v-btn
                                  >
                                </div>
                              </v-slide-x-transition>
                            </v-col> -->
                          </v-row>
                          <v-file-input
                            v-model="newAdditionalImages"
                            placeholder="Upload your documents"
                            label="File input"
                            multiple
                            prepend-icon="mdi-paperclip"
                          >
                            <template #selection="{ text }">
                              <v-chip small label color="primary">
                                {{ text }}
                              </v-chip>
                            </template>
                          </v-file-input>
                          <v-btn class="primary" @click="addimageUpload"
                            >Save image</v-btn
                          >
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          Video Links
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group row">
                                <label class="col-12 col-form-label">
                                  <strong>YouTube </strong> <br />
                                  You must use the embed url to add a video to
                                  the product listing page, example: FC7a2uE-9_o
                                </label>
                                <div class="col-12">
                                  <input
                                    id="youtube"
                                    v-model="product.youtube"
                                    class="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-5 col-form-label"
                                  >Vimeo</label
                                >
                                <div class="col-12">
                                  <input
                                    id="vimeo"
                                    v-model="product.vimeo"
                                    class="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-card color="warning">
                      <v-card-text>
                        This action can not be undone and will also delete all
                        items.
                        <v-btn
                          elevation="9"
                          small
                          rounded
                          outlined
                          class=" mt-6  mr-2"
                          @click="removeProductBtn"
                        >
                          <v-icon left>
                            mdi-delete
                          </v-icon>
                          Delete Product
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-form>

      <v-dialog v-model="addDialog" persistent max-width="600px">
        <add-new-item @exit="closeModal" />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'

import { mapGetters, mapActions } from 'vuex'
// import ImageInput from '../../../components/ImageInput.vue'
import AddNewItem from '../../../components/pim/attributesets/AddNewItem.vue'

import { validationMixin } from 'vuelidate'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import moment from 'moment'
import AssortmentAddProduct from '@/graphql/mutations/AssortmentAddProduct.gql'
import UpdateFullProduct from '@/graphql/mutations/UpdateFullProduct.gql'
import GetCompanyTags from '@/graphql/queries/GetCompanyTags.gql'
import UploadFile from '@/graphql/mutations/UploadFile.gql'
import GetItems from '@/graphql/queries/GetItems.gql'
import GetProduct from '@/graphql/queries/GetProduct.gql'
import GetProductsBySupplier from '@/graphql/queries/GetProductsBySupplier.gql'
import GetItemsCount from '@/graphql/queries/GetItemsCount.gql'

export default {
  components: {
    // ImageInput: ImageInput,
    AddNewItem,
  },
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      })
      return formatter.format(value)
    },
  },
  mixins: [validationMixin],
  data: () => ({
    avatar: null,
    panel: [0, 1],
    panel2: [0, 1],
    accountTab: 'General',
    selected: 2,
    e6: 0,
    saving: false,
    saved: false,
    clothing_style: '',
    id: '',
    demo1: '',
    demo2: '',
    chipsearch: '',
    isLoading: false,
    youtube: '',
    vimeo: '',
    additional_image: null,
    main_image: null,
    limit: 10,
    chips: [],
    currentPage: 1,
    totalCount: 0,
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => "The email and password you entered don't match",
    },
    name: '',
    part_id: '',
    case_pack: '',
    url_key: '',
    weight: '',
    length_in: '',
    width_in: '',
    height_in: '',
    shipping_info: '',
    brand: '',
    status: '',
    featured: '',
    second_name: '',
    created_at: '',
    product_options: [
      { value: 'new', text: 'New' },
      { value: 'pending', text: 'Pending' },
      { value: 'discontinued', text: 'Discontinued' },
      { value: 'active', text: 'Active' },
    ],
    type: '',
    type_options: [
      { value: '', text: 'Choose Type' },
      { value: 'simple', text: 'Simple' },
      { value: 'has_variants', text: 'Has Variants' },
      { value: 'virtual', text: 'Virtual' },
    ],
    attribute_set: '',
    attribute_set_options: [
      { value: '', text: 'Choose Set of Attributes' },
      { value: 'clothing', text: 'Clothing' },
      { value: 'demo1', text: 'Demo 1' },
      { value: 'demo2', text: 'Demo 2' },
    ],
    short_description: '',
    long_description: '',
    fields: [
      {
        key: 'name',
        label: 'Name',
      },
      {
        key: 'sku',
        label: 'Sku #',
      },

      {
        key: 'qty',
        label: 'Qty',
      },
      {
        key: 'actions',
        label: 'Actions',
      },
    ],
    items: [],
    newAdditionalImages: [],
    addDialog: false,
    addDialogformvalid: false,
    addDialogform: {
      name: '',
      skuid: '',
    },
    baseurl: process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337',

    editor: ClassicEditor,

    editorConfig: {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'bulletedList',
          'numberedList',
          '|',
          'insertTable',
          '|',
          'undo',
          'redo',
        ],
      },
    },
    options: [
      { value: null, text: 'New' },
      { value: 'draft', text: 'Draft' },
      { value: 'awaiting', text: 'Awaiting Approval' },
      { value: 'active', text: 'Active ' },
      { value: 'disco', text: 'Discontinued' },
      { value: 'eol', text: 'End of Life' },
    ],
    logic: ['Active', 'Pending', 'Discontinued', 'NOT Discontinued'],

    dialog: false,
    dialog2: false,
  }),
  computed: {
    ...mapGetters(['currentUser']),
    tagNames: function() {
      const tagHeader = [{ header: 'Select an option or create one' }]
      const allTags = this.tags ? this.tags.map((t) => t.name) : []
      return [].concat(tagHeader, allTags)
    },
    additional_images() {
      if (this.product.additional_image.length > 0) {
        return this.product.additional_image
      } else {
        return []
      }
    },
  },
  apollo: {
    product: {
      query: GetProduct,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
    },
    tags: {
      query: GetCompanyTags,
      variables() {
        return {
          companyId: this.$store.state.auth.user.company_relation,
        }
      },
    },
    items: {
      query: GetItems,
      fetchPolicy: 'network-only',
      variables() {
        return {
          product: parseInt(this.$route.params.id),
          limit: this.limit,
          start: (this.currentPage - 1) * this.limit,
        }
      },
    },
    itemsCount: {
      query: GetItemsCount,
      variables() {
        return {
          productId: this.$route.params.id,
        }
      },
    },
  },
  watch: {
    product: {
      immediate: true,
      handler: function(newValue) {
        this.chips = newValue ? newValue.tags.map((t) => t.name) : []
        this.status = newValue ? newValue.status : 'new'
        this.featured = newValue ? newValue.featured : false
      },
    },
  },
  created() {
    this.loadAll()
  },
  methods: {
    closeModal() {
      this.addDialog = false
    },
    loadAll() {
      // if (this.$route.params.id != '' && this.$route.params.id != undefined) {
      //   this.getProductById({
      //     id: this.$route.params.id,
      //   }).then((res) => {
      //     if (res.data != false) {
      //       this.name = res.data.name
      //       this.part_id = res.data.part_id
      //       this.created_at = res.data.created_at
      //       this.status = res.data.status
      //       this.case_pack = res.data.case_pack
      //       this.featured = res.data.featured
      //       // might want the next two lines in an if statement
      //       this.short_description = res.data.short_description || ''
      //       this.long_description = res.data.long_description || ''
      //       this.youtube = res.data.youtube
      //       this.vimeo = res.data.vimeo
      //       this.url_key = res.data.url_key
      //       this.weight = res.data.weight
      //       this.length_in = res.data.length_in
      //       this.width_in = res.data.width_in
      //       this.height_in = res.data.height_in
      //       if (
      //         res.data.shipping_info != undefined &&
      //         res.data.shipping_info != null
      //       ) {
      //         this.shipping_info = res.data.shipping_info
      //       }
      //       if (
      //         res.data.marketing_text != undefined &&
      //         res.data.marketing_text != null
      //       ) {
      //         this.marketing_text = res.data.marketing_text
      //       }
      //       if (
      //         res.data.ingredients != undefined &&
      //         res.data.ingredients != null
      //       ) {
      //         this.ingredients = res.data.ingredients
      //       }
      //       this.brand = res.data.brand
      //       this.second_name = res.data.second_name
      //       if (res.data.main_image != null) {
      //         this.main_image_id = res.data.main_image.id
      //         this.main_image_url = res.data.main_image.url
      //         this.main_image_name =
      //           res.data.main_image.name + res.data.main_image.ext
      //       }
      //       if (res.data.additional_image != null) {
      //         this.additional_images = res.data.additional_image
      //       }
      //       if (res.data.tags !== null) {
      //         this.chips = res.data.tags.map((t) => t.name)
      //       }
      //       this.type = res.data.type
      //       this.attribute_set = res.data.attribute_set
      //     } else {
      //       this.$router.push('/pim/list')
      //     }
      //   })
      // .catch((err) => {
      // });
      // this.getItemTotalCount({
      //   params: 'product=' + this.$route.params.id,
      // }).then((res) => {
      //   this.totalCount = res.data
      // })
      // }
    },
    uploadImage() {
      this.saving = true
      setTimeout(() => this.savedAvatar(), 1000)
    },
    savedAvatar() {
      this.saving = false
      this.saved = true
    },

    ...mapActions([
      'getProductWhere',
      'getAssortmentProductTotalCount',
      'getAssortmentsBySupplier',
      'getProductById',
      'deleteFile',
      'removeProduct',
      'submitItem',
      'getItemTotalCount',
      'removeItem',
      'updateProductAdditionalImg',
      'getProductByAssortment',
      'getProductTotalCount',
      'getItemWhere',
    ]),
    async addimageUpload() {
      if (this.newAdditionalImages.length > 0) {
        await Promise.all(
          this.newAdditionalImages.map(async (img) => {
            await this.$apollo.mutate({
              mutation: UploadFile,
              variables: {
                contentType: 'product',
                contentId: this.$route.params.id,
                field: 'additional_image',
                file: img,
              },
            })
          })
        )
        this.newAdditionalImages = []
        this.$apollo.queries.product.refetch()
        // await this.refreshAdditionalImg()
      }
    },
    removeImg(id) {
      if (id != '' && id != undefined) {
        this.$bvModal
          .msgBoxConfirm('Confirm Delete Are you sure?', {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              this.deleteFile({
                id: id,
              }).then(() => {
                this.$apollo.queries.product.refetch()
              })
            }
          })
      }
    },
    // imageChange(e) {
    //   let target = e.target || e.srcElement
    //   let files = target.files
    //   let fr = new FileReader()
    //   fr.onload = function() {
    //     document.getElementById('image_cover').src = fr.result
    //   }
    //   fr.readAsDataURL(files[0])
    // },
    imageDelete() {
      this.product.main_image = ''
    },

    removeProductBtn() {
      if (this.$route.params.id != '' && this.$route.params.id != undefined) {
        this.$bvModal
          .msgBoxConfirm('Are you sure you want to delete?', {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              this.removeProduct({
                id: this.$route.params.id,
              }).then((res) => {
                // async data from ecom server
                // this.$apollo.mutate({
                //   client: 'ecomClient',
                //   mutation: gql`
                //       mutation RemoveProductMutation($id: Int!) {
                //         removeProduct(
                //           id: $id
                //         )
                //       }
                //     `
                //   ,
                //   variables: {
                //     id: parseInt(this.$route.params.id)
                //   },
                // });
                if (res.data != false) {
                  Swal.fire({
                    title: '',
                    text: 'The product has been successfully removed!',
                    icon: 'success',
                    confirmButtonClass: 'btn btn-secondary',
                  })
                } else {
                  Swal.fire({
                    title: '',
                    text: "You don't have permission to remove this product.",
                    icon: 'error',
                    confirmButtonClass: 'btn btn-secondary',
                  })
                }

                this.$router.push('/pim/list')
              })
            }
          })
      }
    },
    removeItemBtn(id) {
      if (id != '' && id != undefined) {
        this.$bvModal
          .msgBoxConfirm(
            'Are you sure you want to delete item, this can not be undone?',
            {
              title: 'Confirm',
              size: 'sm',
              buttonSize: 'md',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value == true) {
              this.removeItem({
                id: id,
              }).then((res) => {
                // async data from ecom server
                // this.$apollo.mutate({
                //   client: 'ecomClient',
                //   mutation: gql`
                //       mutation RemoveItemMutation($id: Int!) {
                //         removeItem(
                //           id: $id
                //         )
                //       }
                //     `
                //   ,
                //   variables: {
                //     id: parseInt(this.$route.params.id)
                //   },
                // });
                if (res.data != false) {
                  Swal.fire({
                    title: '',
                    text: 'The item has been successfully removed!',
                    icon: 'success',
                    confirmButtonClass: 'btn btn-secondary',
                  })
                  this.refreshItems()
                } else {
                  Swal.fire({
                    title: '',
                    text: "You don't have permission to remove this item.",
                    icon: 'error',
                    confirmButtonClass: 'btn btn-secondary',
                  })
                }
              })
            }
          })
      }
    },
    async submitAssortmentproduct(assortmentId, productId) {
      await this.$apollo.mutate({
        mutation: AssortmentAddProduct,
        variables: { assortmentId: assortmentId, productId },
      })
    },
    async submit(e) {
      e.preventDefault()

      this.isLoading = true
      try {
        if (this.$route.params.id != '' && this.$route.params.id != undefined) {
          const partid_products = await this.$apollo.query({
            query: GetProductsBySupplier,
            variables: {
              supplierId: this.$store.state.auth.user.company_relation,
              partId: this.product.part_id,
            },
          })
          // let partid_products = await this.getProductWhere({
          //   where:
          //     '?_where[0][part_id]=' +
          //     this.part_id +
          //     '&_where[1][supplier]=' +
          //     this.$store.state.auth.user.company_relation +
          //     '&_where[2][id_ne]=' +
          //     this.$route.params.id,
          // })
          if (partid_products.data.products.length > 0) {
            if (partid_products.data.products[0].id != this.$route.params.id) {
              this.isLoading = false
              Swal.fire({
                title: '',
                text:
                  'A product with same SKU/Part # already exists in the company.',
                icon: 'error',
                confirmButtonClass: 'btn btn-secondary',
              })
              return
            }
          }
          const productUpdate = {
            name: this.product.name,
            part_id: this.product.part_id,
            status: this.status,
            featured: this.featured,
            short_description: this.product.short_description,
            long_description: this.product.long_description,
            case_pack: this.product.case_pack
              ? parseInt(this.product.case_pack)
              : undefined,
            url_key: this.product.url_key,
            weight: this.product.weight
              ? parseFloat(this.product.weight)
              : undefined,
            length_in: this.product.length_in
              ? parseFloat(this.product.length_in)
              : undefined,
            width_in: this.product.width_in
              ? parseFloat(this.product.width_in)
              : undefined,
            height_in: this.product.height_in
              ? parseFloat(this.product.height_in)
              : undefined,
            shipping_info: this.product.shipping_info,
            marketing_text: this.product.marketing_text,
            ingredients: this.product.ingredients,
            brand: this.product.brand,
            second_name: this.product.second_name,
            youtube: this.product.youtube,
            vimeo: this.product.vimeo,
            type: this.product.type ? this.product.type : undefined,
            attribute_set: this.product.attribute_set
              ? this.product.attribute_set
              : undefined,
            clothing_style: this.clothing_style,
            demo1: this.demo1,
            demo2: this.demo2,
          }
          if (this.main_image) {
            const hasImageToUpload = this.main_image instanceof File
            if (this.product.main_image) {
              const isDeletingImage = this.product.main_image === ''
              const hasDeletableImage =
                this.product.main_image.id !== undefined &&
                this.product.main_image.id !== ''
              if ((hasImageToUpload || isDeletingImage) && hasDeletableImage) {
                await this.deleteFile({
                  id: this.product.main_image.id,
                })
              }
            }

            if (hasImageToUpload) {
              await this.$apollo.mutate({
                mutation: UploadFile,
                variables: {
                  contentType: 'product',
                  contentId: this.$route.params.id,
                  field: 'main_image',
                  file: this.main_image,
                },
              })
            }
          }
          await this.$apollo.mutate({
            mutation: UpdateFullProduct,
            variables: {
              id: this.$route.params.id,
              productUpdate,
              searchTags: this.chips,
            },
          })

          // refreshes tags incase a new one was created
          this.$apollo.queries.tags.refetch()
          this.$apollo.queries.product.refetch()
          this.loadAll()

          // async data from ecom server
          // await this.$apollo.mutate({
          //   client: "ecomClient",
          //   mutation: gql`
          //     mutation UpdateProductMutation($id: Int!) {
          //       updateProduct(id: $id)
          //     }
          //   `,
          //   variables: {
          //     id: parseInt(this.$route.params.id),
          //   },
          // });

          this.isLoading = false

          Swal.fire({
            title: '',
            timer: 1500,
            timerProgressBar: true,
            text: 'The product has been successfully updated!',
            icon: 'success',
            confirmButtonClass: 'btn btn-secondary',
          })
        }
      } catch (error) {
        this.isLoading = false
        Swal.fire({
          title: '',
          text: 'An Error Occured',
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary',
        })
      }
    },
    refreshItems() {
      this.$apollo.queries.items.refetch()
      this.$apollo.queries.itemsCount.refetch()

      // this.getItemTotalCount({
      //   params: 'product=' + this.$route.params.id,
      // }).then((res) => {
      //   this.totalCount = res.data
      // })
    },
  },
}
</script>
