<template>
  <v-form v-model="addDialogformvalid" @submit.prevent="addItem">
    <v-card>
      <v-card-title>
        <span class="headline">Add New Item</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="name"
                label="Name"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="skuId"
                label="SKU #"
                :rules="[rules.required]"
                @keyup="lowerCase"
                @keydown.space.prevent
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="addDialogClose">
          Close
        </v-btn>
        <v-btn
          type="submit"
          :disabled="!addDialogformvalid"
          color="blue darken-1"
          text
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import Swal from 'sweetalert2'
import AddNewItem from '@/graphql/mutations/AddNewItem.gql'
import GetItems from '@/graphql/queries/GetItems.gql'
import gql from 'graphql-tag'
export default {
  data: () => ({
    name: '',
    skuId: '',
    rules: {
      required: (value) => !!value || 'Required.',
    },
    addDialogformvalid: false,
    limit: 10,
    currentPage: 1,
  }),
  methods: {
    lowerCase() {
      this.skuId = this.skuId.toLowerCase()
    },
    addDialogClose() {
      this.name = ''
      this.skuId = ''
      this.$emit('exit')
    },
    async addItem() {
      let items = await this.$apollo.query({
        query: gql`
          query Items($sku_id: String, $supplier: ID!) {
            items: items(
              where: { sku_id: $sku_id, product: { supplier: $supplier } }
            ) {
              id
              Name
              sku_id
            }
          }
        `,
        variables: {
          sku_id: this.skuId,
          supplier: this.$store.state.auth.user.company_relation,
        },
      })
      if (items.data.items.length > 0) {
        this.isLoading = false
        Swal.fire({
          title: '',
          text: 'An item with same SKU/Part # already exists in the company.',
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary',
        })
        return
      } else {
        try {
          await this.$apollo.mutate({
            mutation: AddNewItem,
            variables: {
              skuId: this.skuId,
              name: this.name,
              productId: this.$route.params.id,
            },
            refetchQueries: [
              {
                query: GetItems,
                variables: {
                  product: parseInt(this.$route.params.id),
                  limit: this.limit,
                  start: (this.currentPage - 1) * this.limit, // TODO use company id
                },
              },
            ],
          })
          ;(this.skuId = ''), (this.name = ''), this.$emit('exit')
          Swal.fire({
            title: '',
                        timer: 1500,
  timerProgressBar: true,
            text: 'The item has been successfully created!',
            icon: 'success',
            confirmButtonClass: 'btn btn-secondary',
          })
        } catch (error) {
          if (error) {
            Swal.fire({
              title: '',
              text:
                'An item with same SKU/Part # already exists in the company.',
              icon: 'error',
              confirmButtonClass: 'btn btn-secondary',
            })
          }
        }
      }
    },
  },
}
</script>
